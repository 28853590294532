<template>
  <div class="switch">
    <!--将switch的项目 通过iframe链接过来-->
    <iframe
      name="hidden_frame"
      id="searchMonitor_frame"
      frameborder="no"
      border="0"
      :src="url"></iframe>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      url: ''
    }
  },
  created () { // 获取switch的地址
    let protocol = window.location.protocol
    this.url = `${protocol}//${this.StateObj.common.baseUrl.searchMonitorUrl}/monitor`
  },
  methods: {},
  computed: {
    ...mapState({
      StateObj: state => state
    })
  }
}
</script>
<style lang="less" scoped>
.switch {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}
</style>
